<template>
  <div>
    <div class="app-wrapper">
      <div class="container py-5">
        <div class="col-lg-10 mx-auto p-0">
          <div class="py-5 d-flex align-items-center justify-content-between">
            <h3 class="ana_h3 mb-0 ana_text-dark">Notifications</h3>
            <div>
              <span class="cancel" @click="$router.go(-1)">
                <i class="ti-close"></i>
              </span>
            </div>
          </div>
          <div class="notif d-block d-md-flex align-items-center px-0 py-4">
            <div class="mr-2 mt-2">
              <div
                class="icon d-flex align-items-center justify-content-center"
              >
                🚀
              </div>
            </div>
            <p class="ana_paragaph text-muted mb-0 mt-2">
              Welcome to <strong>ana!!!</strong> Heads up, this is still heavily
              under development. So if you are here I must have shared the link
              directly with you. A lot might look differently, from time to
              time. But not to worry, I will let you know when it is 100% safe
              to use 🙈
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Notifications",
  metaInfo: {
    title: "Notification - Ana app",
  },
};
</script>
